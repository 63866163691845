export default {
  tools: [
    {
      id: "chalk",
      type: "brush",
      isActive: true,
      group: "default"
    },
    {
      id: "eraser",
      type: "brush",
      isActive: false,
      group: "default"
    },
    {
      id: "stickers",
      type: "element",
      isActive: false,
      group: "group2"
    },
    {
      id: "customContent",
      isActive: false,
    },
  ],
  swatches: [],
  modals: [
    {
      id: "colorWheel",
      isActive: false,
    },
    {
      id: "reset",
      isActive: false,
    },
    {
      id: "download",
      isActive: false,
    },
  ],
  groups: [
    {
      id: "default",
      isActive: true,
    },
    {
      id: "group2",
      isActive: false,
    }
  ],
  articles: [
    {
      id: 'bear1',
      isActive: false,
    },
    {
      id: 'bear2',
      isActive: false,
    },
    {
      id: 'bear3',
      isActive: false,
    },
  ]
}